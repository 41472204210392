import styled from '@emotion/styled';

export const StyledIframe = styled.div`
    margin-bottom: 6rem;
    width: 100%;
    height: 100%;

    iframe {
        width: 100%;
    }
`;
