/**
 * @file             : services/frontend/src/www-src/scripts/components/iframe/index.jsx
 * @author           : Camilo Tapia <camilo.tapia@gmail.com>
 * Last Modified Date: 02.11.2017
 * Last Modified By  : Camilo Tapia <camilo.tapia@gmail.com>
 */
import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';

import { StyledIframe } from './style';

const Iframe = props => {
    let { iframe } = props;
    return <StyledIframe dangerouslySetInnerHTML={{ __html: iframe }} />;
};

Iframe.propTypes = {
    iframe: PropTypes.string,
};

Iframe.vcConvertToProps = atts => {
    let newProps = {
        iframe: atts.iframe_code,
    };
    return newProps;
};

Iframe.parseProps = atts => {
    return {
        iframe: atts.iframe,
    };
};

export default Iframe;
